import React, { useCallback } from 'react';
import { graphql } from 'gatsby';
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';
import { StaticImage } from 'gatsby-plugin-image';
import Typed from 'react-typed';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Navigation, A11y, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// Components
import Layout from '../components/layout/Layout';
import CommunityManagementSimulatorSection from '../components/section/simulator/CommunityManagement';
import CommunityManagementPlansSection from '../components/section/plans/CommunityManagement';
import RealisationsSection from '../components/section/Realisations';
import TestimonialsSection from '../components/section/Testimonials';

// Images
import headerBackgroundImage from '../images/svg/components/card-11.svg';

const OnePagePage = ({ data }) => {

  const { t } = useTranslation();

  // Tawk Toggle
  const handleClickTawk = useCallback(
    e => {
      e.preventDefault();
      window.Tawk_API.toggle();
    },
    []
  );

  return (
    <Layout
      pageType="classic"
      pageTitle={t('meta.title')}
      pageDescription={t('meta.description')}
      pageKeywords={t('meta.keywords', { title: data.site.siteMetadata.title })}
      headerClass="navbar-absolute-top navbar-light navbar-show-hide"
      headerStyle="light"
    >
      <div>
        <div className="position-relative bg-img-start" style={{ backgroundImage: 'url(' + headerBackgroundImage + ')' }} data-aos="fade-up">
          <div className="container content-space-t-4 content-space-b-1 content-space-b-lg-3">
            <div className="row justify-content-lg-between align-items-lg-center pt-lg-5">
              <div className="col-md-8 col-lg-5">
                <div className="mb-5">
                  <h1 className="display-6 mb-3">{t('title')}
                    <br></br>
                    <span className="text-primary text-highlight-behind-warning">
                      <Typed
                        strings={t('titleTyped').split(',')}
                        typeSpeed={40}
                        backSpeed={50}
                        loop >
                      </Typed>
                    </span>
                  </h1>
                  <p className="lead">{t('subtitle')}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 d-none d-lg-block position-lg-absolute bottom-0 end-0 transform-rotate-n22deg me-lg-n10">
            <div className="row gx-3 align-items-end">
              <div className="col">
                <div className="d-grid gap-3">
                  <div data-aos="fade-up" data-aos-offset="0">
                    <StaticImage className="img-fluid shadow-lg rounded-2" quality="30" src="../images/illustrations/community-management/la-small-agency-community-management-1.svg" alt={t('image.alt', { title: t('common.image.alt') })} />
                  </div>
                  <div data-aos="fade-up" data-aos-delay="100" data-aos-offset="0">
                    <StaticImage className="img-fluid shadow-lg rounded-2" quality="30" src="../images/illustrations/community-management/la-small-agency-community-management-2.svg" alt={t('image.alt', { title: t('common.image.alt') })} />
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="d-grid gap-3">
                  <div data-aos="fade-up" data-aos-delay="200" data-aos-offset="0">
                    <StaticImage className="img-fluid shadow-lg rounded-2" quality="30" src="../images/illustrations/community-management/la-small-agency-community-management-3.svg" alt={t('image.alt', { title: t('common.image.alt') })} />
                  </div>
                  <div data-aos="fade-up" data-aos-delay="300" data-aos-offset="0">
                    <StaticImage className="img-fluid shadow-lg rounded-2" quality="30" src="../images/illustrations/community-management/la-small-agency-community-management-4.svg" alt={t('image.alt', { title: t('common.image.alt') })} />
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="d-grid gap-3">
                  <div data-aos="fade-up" data-aos-delay="400" data-aos-offset="0">
                    <StaticImage className="img-fluid shadow-lg rounded-2" quality="30" src="../images/illustrations/community-management/la-small-agency-community-management-5.svg" alt={t('image.alt', { title: t('common.image.alt') })} />
                  </div>
                  <div data-aos="fade-up" data-aos-delay="500" data-aos-offset="0">
                    <StaticImage className="img-fluid shadow-lg rounded-2" quality="30" src="../images/illustrations/community-management/la-small-agency-community-management-6.svg" alt={t('image.alt', { title: t('common.image.alt') })} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container content-space-t-2 content-space-b-md-2 content-space-t-lg-4">
          <div className="w-md-75 w-lg-75 text-center mx-md-auto mb-5 mb-md-9">
            <h2>{t('benefits.title')}</h2>
            <p>{t('benefits.subtitle')}</p>
          </div>
          <div className="row gx-3">
            <div className="col-sm-6 col-lg-3 mb-3 mb-lg-0">
              <AnchorLink className="card card-sm card-transition h-100 pointer" href="#checklist">
                <div className="card-body">
                  <span className="svg-icon text-primary mb-3">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M21.7 18.9L18.6 15.8C17.9 16.9 16.9 17.9 15.8 18.6L18.9 21.7C19.3 22.1 19.9 22.1 20.3 21.7L21.7 20.3C22.1 19.9 22.1 19.3 21.7 18.9Z" fill="#035A4B"></path>
                      <path opacity="0.3" d="M11 20C6 20 2 16 2 11C2 6 6 2 11 2C16 2 20 6 20 11C20 16 16 20 11 20ZM11 4C7.1 4 4 7.1 4 11C4 14.9 7.1 18 11 18C14.9 18 18 14.9 18 11C18 7.1 14.9 4 11 4ZM8 11C8 9.3 9.3 8 11 8C11.6 8 12 7.6 12 7C12 6.4 11.6 6 11 6C8.2 6 6 8.2 6 11C6 11.6 6.4 12 7 12C7.6 12 8 11.6 8 11Z" fill="#035A4B"></path>
                    </svg>
                  </span>
                  <div className="h4 card-title">{t('benefits.visibility.title')}</div>
                  <p className="card-text text-body">{t('benefits.visibility.subtitle')}</p>
                </div>
                <div className="card-footer pt-0">
                  <span className="card-link">{t('cta.learnMore')} <i className="bi-chevron-right small ms-1"></i></span>
                </div>
              </AnchorLink>
            </div>
            <div className="col-sm-6 col-lg-3 mb-3 mb-lg-0">
              <AnchorLink className="card card-sm card-transition h-100 pointer" href="#checklist">
                <div className="card-body">
                  <span className="svg-icon text-primary mb-3">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M21 10H13V11C13 11.6 12.6 12 12 12C11.4 12 11 11.6 11 11V10H3C2.4 10 2 10.4 2 11V13H22V11C22 10.4 21.6 10 21 10Z" fill="#035A4B"></path>
                      <path opacity="0.3" d="M12 12C11.4 12 11 11.6 11 11V3C11 2.4 11.4 2 12 2C12.6 2 13 2.4 13 3V11C13 11.6 12.6 12 12 12Z" fill="#035A4B"></path>
                      <path opacity="0.3" d="M18.1 21H5.9C5.4 21 4.9 20.6 4.8 20.1L3 13H21L19.2 20.1C19.1 20.6 18.6 21 18.1 21ZM13 18V15C13 14.4 12.6 14 12 14C11.4 14 11 14.4 11 15V18C11 18.6 11.4 19 12 19C12.6 19 13 18.6 13 18ZM17 18V15C17 14.4 16.6 14 16 14C15.4 14 15 14.4 15 15V18C15 18.6 15.4 19 16 19C16.6 19 17 18.6 17 18ZM9 18V15C9 14.4 8.6 14 8 14C7.4 14 7 14.4 7 15V18C7 18.6 7.4 19 8 19C8.6 19 9 18.6 9 18Z" fill="#035A4B"></path>
                    </svg>
                  </span>
                  <div className="h4 card-title">{t('benefits.attractiveness.title')}</div>
                  <p className="card-text text-body">{t('benefits.attractiveness.subtitle')}</p>
                </div>
                <div className="card-footer pt-0">
                  <span className="card-link">{t('cta.learnMore')} <i className="bi-chevron-right small ms-1"></i></span>
                </div>
              </AnchorLink>
            </div>
            <div className="col-sm-6 col-lg-3 mb-3 mb-sm-0">
              <AnchorLink className="card card-sm card-transition h-100 pointer" href="#checklist">
                <div className="card-body">
                  <span className="svg-icon text-primary mb-3">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path opacity="0.3" d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM12 7C10.3 7 9 8.3 9 10C9 11.7 10.3 13 12 13C13.7 13 15 11.7 15 10C15 8.3 13.7 7 12 7Z" fill="#035A4B"></path>
                      <path d="M12 22C14.6 22 17 21 18.7 19.4C17.9 16.9 15.2 15 12 15C8.8 15 6.09999 16.9 5.29999 19.4C6.99999 21 9.4 22 12 22Z" fill="#035A4B"></path>
                    </svg>
                  </span>
                  <div className="h4 card-title">{t('benefits.community.title')}</div>
                  <p className="card-text text-body">{t('benefits.community.subtitle')}</p>
                </div>
                <div className="card-footer pt-0">
                  <span className="card-link">{t('cta.learnMore')} <i className="bi-chevron-right small ms-1"></i></span>
                </div>
              </AnchorLink>
            </div>
            <div className="col-sm-6 col-lg-3">
              <AnchorLink className="card card-sm card-transition h-100 pointer" href="#checklist">
                <div className="card-body">
                  <span className="svg-icon text-primary mb-3">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M15.43 8.56949L10.744 15.1395C10.6422 15.282 10.5804 15.4492 10.5651 15.6236C10.5498 15.7981 10.5815 15.9734 10.657 16.1315L13.194 21.4425C13.2737 21.6097 13.3991 21.751 13.5557 21.8499C13.7123 21.9488 13.8938 22.0014 14.079 22.0015H14.117C14.3087 21.9941 14.4941 21.9307 14.6502 21.8191C14.8062 21.7075 14.9261 21.5526 14.995 21.3735L21.933 3.33649C22.0011 3.15918 22.0164 2.96594 21.977 2.78013C21.9376 2.59432 21.8452 2.4239 21.711 2.28949L15.43 8.56949Z" fill="#035A4B"></path>
                      <path opacity="0.3" d="M20.664 2.06648L2.62602 9.00148C2.44768 9.07085 2.29348 9.19082 2.1824 9.34663C2.07131 9.50244 2.00818 9.68731 2.00074 9.87853C1.99331 10.0697 2.04189 10.259 2.14054 10.4229C2.23919 10.5869 2.38359 10.7185 2.55601 10.8015L7.86601 13.3365C8.02383 13.4126 8.19925 13.4448 8.37382 13.4297C8.54839 13.4145 8.71565 13.3526 8.85801 13.2505L15.43 8.56548L21.711 2.28448C21.5762 2.15096 21.4055 2.05932 21.2198 2.02064C21.034 1.98196 20.8409 1.99788 20.664 2.06648V2.06648Z" fill="#035A4B"></path>
                    </svg>
                  </span>
                  <div className="h4 card-title">{t('benefits.responsiveness.title')}</div>
                  <p className="card-text text-body">{t('benefits.responsiveness.subtitle')}</p>
                </div>
                <div className="card-footer pt-0">
                  <span className="card-link">{t('cta.learnMore')} <i className="bi-chevron-right small ms-1"></i></span>
                </div>
              </AnchorLink>
            </div>
          </div>
        </div>
        <div className="gradient-y-sm-primary overflow-hidden" id="checklist">
          <div className="container content-space-2 content-space-lg-3">
            <div className="w-md-75 w-lg-75 text-center mx-md-auto mb-5 mb-md-9">
              <h2 dangerouslySetInnerHTML={{ __html: t('checklist.title') }}></h2>
            </div>
            <div className="w-md-100 mx-md-auto">
              <div className="row justify-content-lg-center align-items-md-center">
                <div className="col-12 col-md-4 mb-7 m-md-0">
                  <div className="position-relative">
                    <div className="card">
                      <div className="card-body">
                        <span className="svg-icon text-primary mb-4">
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M19.5 4C20.3284 4 21 4.67157 21 5.5V17.5C21 18.3284 20.3284 19 19.5 19H4.5C3.67157 19 3 18.3284 3 17.5V14.5C3 13.6716 3.67157 13 4.5 13H9V9.5C9 8.67157 9.67157 8 10.5 8H15V5.5C15 4.67157 15.6716 4 16.5 4H19.5Z" fill="#035A4B"></path>                          </svg>
                        </span>
                        <h3 className="card-title">{t('checklist.card.title')}</h3>
                        <p className="card-text">{t('checklist.card.subtitle')}</p>
                      </div>
                    </div>
                    <figure className="position-absolute bottom-0 start-0 zi-n1 mb-n7 ms-n7" style={{ width: '12rem' }}>
                      <StaticImage className="img-fluid" quality="30" src="../images/svg/components/dots-lg.svg" alt={t('checklist.image.alt', { title: t('common.image.alt') })} />
                    </figure>
                  </div>
                </div>
                <div className="col-12 col-md-8">
                  <div className="row justify-content-lg-center align-items-md-center">
                    <div className="col-6 col-lg-3">
                      <ul className="list-checked list-checked-soft-bg-primary list-checked-lg mb-5">
                        <li className="list-checked-item">{t('checklist.restaurant')}</li>
                        <li className="list-checked-item">{t('checklist.cafe')}</li>
                        <li className="list-checked-item">{t('checklist.bar')}</li>
                        <li className="list-checked-item">{t('checklist.bakery')}</li>
                        <li className="list-checked-item">{t('checklist.bookStore')}</li>
                        <li className="list-checked-item">{t('checklist.concertVenue')}</li>
                      </ul>
                    </div>
                    <div className="col-6 col-lg-3">
                      <ul className="list-checked list-checked-soft-bg-primary list-checked-lg mb-5">
                        <li className="list-checked-item">{t('checklist.grocery')}</li>
                        <li className="list-checked-item">{t('checklist.hotel')}</li>
                        <li className="list-checked-item">{t('checklist.movieTheatre')}</li>
                        <li className="list-checked-item">{t('checklist.museum')}</li>
                        <li className="list-checked-item">{t('checklist.artGallery')}</li>
                        <li className="list-checked-item">{t('checklist.realEstate')}</li>
                        <li className="list-checked-item">{t('checklist.school')}</li>
                      </ul>
                    </div>
                    <div className="col-6 col-lg-3">
                      <ul className="list-checked list-checked-soft-bg-primary list-checked-lg mb-5">
                        <li className="list-checked-item">{t('checklist.spas')}</li>
                        <li className="list-checked-item">{t('checklist.personalCare')}</li>
                        <li className="list-checked-item">{t('checklist.automobiles')}</li>
                        <li className="list-checked-item">{t('checklist.consulting')}</li>
                        <li className="list-checked-item">{t('checklist.nutritionist')}</li>
                        <li className="list-checked-item">{t('checklist.beauty')}</li>
                      </ul>
                    </div>
                    <div className="col-6 col-lg-3">
                      <ul className="list-checked list-checked-soft-bg-primary list-checked-lg mb-5">
                        <li className="list-checked-item">{t('checklist.insurance')}</li>
                        <li className="list-checked-item">{t('checklist.software')}</li>
                        <li className="list-checked-item">{t('checklist.legal')}</li>
                        <li className="list-checked-item">{t('checklist.nonProfitOrganization')}</li>
                        <li className="list-checked-item">{t('checklist.media')}</li>
                        <li className="list-checked-item">{t('checklist.travel')}</li>
                      </ul>
                    </div>
                  </div>
                  <AnchorLink className="link" href="#plans">{t('cta.ourPlans')} <i className="bi-chevron-right small ms-1"></i></AnchorLink>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container content-space-2">
          <div className="w-md-75 w-lg-75 text-center mx-md-auto mb-5 mb-md-9">
            <h2 dangerouslySetInnerHTML={{ __html: t('process.title') }}></h2>
          </div>
          <div className="row align-items-lg-center">
            <div className="col-lg-5 order-lg-2 mb-7 mb-lg-0">
              <div>
                <div className="nav nav-sm nav-pills nav-pills-shadow flex-lg-column gap-lg-1 p-3 row" role="tablist">
                  <a className="nav-item nav-link col-12 active" href="#process-conversation" id="process-conversation-tab" data-bs-toggle="tab" data-bs-target="#process-conversation" role="tab" aria-controls="process-conversation" aria-selected="true" style={{ minWidth: '20rem' }}>
                    <div className="d-flex align-items-center align-items-lg-start">
                      <span className="svg-icon svg-icon-sm text-primary">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" clipRule="evenodd" d="M22.1671 18.1421C22.4827 18.4577 23.0222 18.2331 23.0206 17.7868L23.0039 13.1053V5.52632C23.0039 4.13107 21.8729 3 20.4776 3H8.68815C7.2929 3 6.16183 4.13107 6.16183 5.52632V9H13C14.6568 9 16 10.3431 16 12V15.6316H19.6565L22.1671 18.1421Z" fill="#035A4B"></path>
                          <path opacity="0.3" fillRule="evenodd" clipRule="evenodd" d="M1.98508 18V13C1.98508 11.8954 2.88051 11 3.98508 11H11.9851C13.0896 11 13.9851 11.8954 13.9851 13V18C13.9851 19.1046 13.0896 20 11.9851 20H4.10081L2.85695 21.1905C2.53895 21.4949 2.01123 21.2695 2.01123 20.8293V18.3243C1.99402 18.2187 1.98508 18.1104 1.98508 18ZM5.99999 14.5C5.99999 14.2239 6.22385 14 6.49999 14H11.5C11.7761 14 12 14.2239 12 14.5C12 14.7761 11.7761 15 11.5 15H6.49999C6.22385 15 5.99999 14.7761 5.99999 14.5ZM9.49999 16C9.22385 16 8.99999 16.2239 8.99999 16.5C8.99999 16.7761 9.22385 17 9.49999 17H11.5C11.7761 17 12 16.7761 12 16.5C12 16.2239 11.7761 16 11.5 16H9.49999Z" fill="#035A4B"></path>
                        </svg>
                      </span>
                      <div className="flex-grow-1 ms-3">
                        <div className="h4 mb-1">{t('process.conversation.title')}</div>
                        <p className="text-body mb-0">{t('process.conversation.subtitle')}</p>
                      </div>
                    </div>
                  </a>
                  <a className="nav-item nav-link col-12" href="#process-discovery" id="process-discovery-tab" data-bs-toggle="tab" data-bs-target="#process-discovery" role="tab" aria-controls="process-discovery" aria-selected="false" style={{ minWidth: '20rem' }}>
                    <div className="d-flex align-items-center align-items-lg-start">
                      <span className="svg-icon svg-icon-sm text-primary">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M21.7 18.9L18.6 15.8C17.9 16.9 16.9 17.9 15.8 18.6L18.9 21.7C19.3 22.1 19.9 22.1 20.3 21.7L21.7 20.3C22.1 19.9 22.1 19.3 21.7 18.9Z" fill="#035A4B"></path>
                          <path opacity="0.3" d="M11 20C6 20 2 16 2 11C2 6 6 2 11 2C16 2 20 6 20 11C20 16 16 20 11 20ZM11 4C7.1 4 4 7.1 4 11C4 14.9 7.1 18 11 18C14.9 18 18 14.9 18 11C18 7.1 14.9 4 11 4ZM8 11C8 9.3 9.3 8 11 8C11.6 8 12 7.6 12 7C12 6.4 11.6 6 11 6C8.2 6 6 8.2 6 11C6 11.6 6.4 12 7 12C7.6 12 8 11.6 8 11Z" fill="#035A4B"></path>
                        </svg>
                      </span>
                      <div className="flex-grow-1 ms-3">
                        <div className="h4 mb-1">{t('process.discovery.title')}</div>
                        <p className="text-body mb-0">{t('process.discovery.subtitle')}</p>
                      </div>
                    </div>
                  </a>
                  <a className="nav-item nav-link col-12" href="#process-plan" id="process-plan-tab" data-bs-toggle="tab" data-bs-target="#process-plan" role="tab" aria-controls="process-plan" aria-selected="false" style={{ minWidth: '20rem' }}>
                    <div className="d-flex align-items-center align-items-lg-start">
                      <span className="svg-icon svg-icon-sm text-primary">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path opacity="0.3" d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM15 17C15 16.4 14.6 16 14 16H8C7.4 16 7 16.4 7 17C7 17.6 7.4 18 8 18H14C14.6 18 15 17.6 15 17ZM17 12C17 11.4 16.6 11 16 11H8C7.4 11 7 11.4 7 12C7 12.6 7.4 13 8 13H16C16.6 13 17 12.6 17 12ZM17 7C17 6.4 16.6 6 16 6H8C7.4 6 7 6.4 7 7C7 7.6 7.4 8 8 8H16C16.6 8 17 7.6 17 7Z" fill="#035A4B"></path>
                          <path d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z" fill="#035A4B"></path>
                        </svg>
                      </span>
                      <div className="flex-grow-1 ms-3">
                        <div className="h4 mb-1">{t('process.plan.title')}</div>
                        <p className="text-body mb-0">{t('process.plan.subtitle')}</p>
                      </div>
                    </div>
                  </a>
                  <a className="nav-item nav-link col-12" href="#process-publish" id="process-publish-tab" data-bs-toggle="tab" data-bs-target="#process-publish" role="tab" aria-controls="process-publish" aria-selected="false" style={{ minWidth: '20rem' }}>
                    <div className="d-flex align-items-center align-items-lg-start">
                      <span className="svg-icon svg-icon-sm text-primary">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" clipRule="evenodd" d="M15 19.5229C15 20.265 15.9624 20.5564 16.374 19.9389L22.2227 11.166C22.5549 10.6676 22.1976 10 21.5986 10H17V4.47708C17 3.73503 16.0376 3.44363 15.626 4.06106L9.77735 12.834C9.44507 13.3324 9.80237 14 10.4014 14H15V19.5229Z" fill="#035A4B"></path>
                          <path opacity="0.3" fillRule="evenodd" clipRule="evenodd" d="M3 6.5C3 5.67157 3.67157 5 4.5 5H9.5C10.3284 5 11 5.67157 11 6.5C11 7.32843 10.3284 8 9.5 8H4.5C3.67157 8 3 7.32843 3 6.5ZM3 18.5C3 17.6716 3.67157 17 4.5 17H9.5C10.3284 17 11 17.6716 11 18.5C11 19.3284 10.3284 20 9.5 20H4.5C3.67157 20 3 19.3284 3 18.5ZM2.5 11C1.67157 11 1 11.6716 1 12.5C1 13.3284 1.67157 14 2.5 14H6.5C7.32843 14 8 13.3284 8 12.5C8 11.6716 7.32843 11 6.5 11H2.5Z" fill="#035A4B"></path>
                        </svg>
                      </span>
                      <div className="flex-grow-1 ms-3">
                        <div className="h4 mb-1">{t('process.publish.title')}</div>
                        <p className="text-body mb-0">{t('process.publish.subtitle')}</p>
                      </div>
                    </div>
                  </a>
                  <a className="nav-item nav-link col-12" href="#process-optimization" id="process-optimization-tab" data-bs-toggle="tab" data-bs-target="#process-optimization" role="tab" aria-controls="process-optimization" aria-selected="false" style={{ minWidth: '20rem' }}>
                    <div className="d-flex align-items-center align-items-lg-start">
                      <span className="svg-icon svg-icon-sm text-primary">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path opacity="0.3" d="M22 5V19C22 19.6 21.6 20 21 20H19.5L11.9 12.4C11.5 12 10.9 12 10.5 12.4L3 20C2.5 20 2 19.5 2 19V5C2 4.4 2.4 4 3 4H21C21.6 4 22 4.4 22 5ZM7.5 7C6.7 7 6 7.7 6 8.5C6 9.3 6.7 10 7.5 10C8.3 10 9 9.3 9 8.5C9 7.7 8.3 7 7.5 7Z" fill="#035A4B"></path>
                          <path d="M19.1 10C18.7 9.60001 18.1 9.60001 17.7 10L10.7 17H2V19C2 19.6 2.4 20 3 20H21C21.6 20 22 19.6 22 19V12.9L19.1 10Z" fill="#035A4B"></path>
                        </svg>
                      </span>
                      <div className="flex-grow-1 ms-3">
                        <div className="h4 mb-1">{t('process.optimization.title')}</div>
                        <p className="text-body mb-0">{t('process.optimization.subtitle')}</p>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-7 order-lg-1">
              <div className="tab-content">
                <div className="tab-pane fade show active" id="process-conversation" role="tabpanel" aria-labelledby="processOne-tab">
                  <StaticImage quality="30" src="../images/illustrations/community-management/la-small-agency-community-management-process-conversation.svg" alt={t('process.conversation.image.alt', { title: t('common.image.alt') })} />
                  <p className="text-center">{t('process.conversation.text')}</p>
                </div>
                <div className="tab-pane fade" id="process-discovery" role="tabpanel" aria-labelledby="process-discovery-tab">
                  <StaticImage quality="30" src="../images/illustrations/community-management/la-small-agency-community-management-process-discovery.svg" alt={t('process.discovery.image.alt', { title: t('common.image.alt') })} />
                  <p className="text-center">{t('process.discovery.text')}</p>
                </div>
                <div className="tab-pane fade" id="process-plan" role="tabpanel" aria-labelledby="process-plan-tab">
                  <StaticImage quality="30" src="../images/illustrations/community-management/la-small-agency-community-management-process-plan.svg" alt={t('process.plan.image.alt', { title: t('common.image.alt') })} />
                  <p className="text-center">{t('process.plan.text')}</p>
                </div>
                <div className="tab-pane fade" id="process-publish" role="tabpanel" aria-labelledby="process-publish-tab">
                  <StaticImage quality="30" src="../images/illustrations/community-management/la-small-agency-community-management-process-publish.svg" alt={t('process.publish.image.alt', { title: t('common.image.alt') })} />
                  <p className="text-center">{t('process.publish.text')}</p>
                </div>
                <div className="tab-pane fade" id="process-optimization" role="tabpanel" aria-labelledby="process-optimization-tab">
                  <StaticImage quality="30" src="../images/illustrations/community-management/la-small-agency-community-management-process-optimization.svg" alt={t('process.optimization.image.alt', { title: t('common.image.alt') })} />
                  <p className="text-center">{t('process.optimization.text')}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="text-center mx-md-auto mt-2 mb-5 mb-md-9" data-aos="fade-up">
            <button className="btn btn-primary btn-transition" onClick={handleClickTawk}><i className="bi bi-chat me-1"></i>{t('cta.letsTalk')}</button>
          </div>
        </div>
        <div className="content-space-2 position-relative zi-2 overflow-hidden">
          <div className="w-md-75 w-lg-75 text-center mx-md-auto mb-5 mb-md-9">
            <span className="text-cap">{t('socialNetworks.title')}</span>
            <h2 dangerouslySetInnerHTML={{ __html: t('socialNetworks.subtitle') }}></h2>
          </div>
          <Swiper
            className="w-75 p-6"
            modules={[Navigation, A11y, Autoplay]}
            navigation={true}
            pagination={{
              dynamicBullets: false,
              clickable: true
            }}
            scrollbar={{ draggable: true }}
            breakpoints={{
              "380": {
                "slidesPerView": 1,
                "spaceBetween": 15,
              },
              "580": {
                "slidesPerView": 2,
                "spaceBetween": 15,
              },
              "768": {
                "slidesPerView": 3,
                "spaceBetween": 15,
              },
              "1024": {
                "slidesPerView": 4,
                "spaceBetween": 30,
              }
            }}
          >
            <SwiperSlide className="my-2">
              <div className="card card-sm card-transition shadow-sm">
                <div className="card-img-top" style={{ backgroundColor: '#F4B400' }}>
                  <div className="m-4 text-center">
                    <div className="avatar avatar-lg rounded mx-auto d-flex justify-content-center flex-wrap p-2">
                      <StaticImage imgClassName="img-fluid" objectFit="contain" quality="30" src="../images/brands/google-icon.svg" alt={t('socialNetworks.google.title')} />
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="h4 card-title">{t('socialNetworks.google.title')}</div>
                  <p className="card-text text-body">{t('socialNetworks.google.subtitle')}</p>
                  <div className="d-grid mt-3">
                    <AnchorLink className="btn btn-outline-primary btn-sm" href="#plans">{t('cta.learnMore')} <i className="bi-chevron-right small ms-1"></i></AnchorLink>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className="my-2">
              <div className="card card-sm card-transition shadow-sm">
                <div className="card-img-top" style={{ backgroundColor: '#0165E1' }}>
                  <div className="m-4 text-center">
                    <div className="avatar avatar-lg rounded mx-auto d-flex justify-content-center flex-wrap p-2">
                      <StaticImage imgClassName="img-fluid" objectFit="contain" quality="30" src="../images/brands/facebook-icon.svg" alt={t('socialNetworks.facebook.title')} />
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="h4 card-title">{t('socialNetworks.facebook.title')}</div>
                  <p className="card-text text-body">{t('socialNetworks.facebook.subtitle')}</p>
                  <div className="d-grid mt-3">
                    <AnchorLink className="btn btn-outline-primary btn-sm" href="#plans">{t('cta.learnMore')} <i className="bi-chevron-right small ms-1"></i></AnchorLink>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className="my-2">
              <div className="card card-sm card-transition shadow-sm">
                <div className="card-img-top" style={{ backgroundColor: '#833AB4' }}>
                  <div className="m-4 text-center">
                    <div className="avatar avatar-lg rounded mx-auto d-flex justify-content-center flex-wrap p-2">
                      <StaticImage imgClassName="img-fluid" objectFit="contain" quality="30" src="../images/brands/instagram-icon.svg" alt={t('socialNetworks.instagram.title')} />
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="h4 card-title">{t('socialNetworks.instagram.title')}</div>
                  <p className="card-text text-body">{t('socialNetworks.instagram.subtitle')}</p>
                  <div className="d-grid mt-3">
                    <AnchorLink className="btn btn-outline-primary btn-sm" href="#plans">{t('cta.learnMore')} <i className="bi-chevron-right small ms-1"></i></AnchorLink>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className="my-2">
              <div className="card card-sm card-transition shadow-sm">
                <div className="card-img-top" style={{ backgroundColor: '#0A66C2' }}>
                  <div className="m-4 text-center">
                    <div className="avatar avatar-lg rounded mx-auto d-flex justify-content-center flex-wrap p-2">
                      <StaticImage imgClassName="img-fluid" objectFit="contain" quality="30" src="../images/brands/linkedin-icon.svg" alt={t('socialNetworks.linkedin.title')} />
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="h4 card-title">{t('socialNetworks.linkedin.title')}</div>
                  <p className="card-text text-body">{t('socialNetworks.linkedin.subtitle')}</p>
                  <div className="d-grid mt-3">
                    <AnchorLink className="btn btn-outline-primary btn-sm" href="#plans">{t('cta.learnMore')} <i className="bi-chevron-right small ms-1"></i></AnchorLink>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className="my-2">
              <div className="card card-sm card-transition shadow-sm">
                <div className="card-img-top" style={{ backgroundColor: '#25F4EE' }}>
                  <div className="m-4 text-center">
                    <div className="avatar avatar-lg rounded mx-auto d-flex justify-content-center flex-wrap p-2">
                      <StaticImage imgClassName="img-fluid" objectFit="contain" quality="30" src="../images/brands/tiktok-icon.svg" alt={t('socialNetworks.tiktok.title')} />
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="h4 card-title">{t('socialNetworks.tiktok.title')}</div>
                  <p className="card-text text-body">{t('socialNetworks.tiktok.subtitle')}</p>
                  <div className="d-grid mt-3">
                    <AnchorLink className="btn btn-outline-primary btn-sm" href="#plans">{t('cta.learnMore')} <i className="bi-chevron-right small ms-1"></i></AnchorLink>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className="my-2">
              <div className="card card-sm card-transition shadow-sm">
                <div className="card-img-top" style={{ backgroundColor: '#E60023' }}>
                  <div className="m-4 text-center">
                    <div className="avatar avatar-lg rounded mx-auto d-flex justify-content-center flex-wrap p-2">
                      <StaticImage imgClassName="img-fluid" objectFit="contain" quality="30" src="../images/brands/pinterest-icon.svg" alt={t('socialNetworks.pinterest.title')} />
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="h4 card-title">{t('socialNetworks.pinterest.title')}</div>
                  <p className="card-text text-body">{t('socialNetworks.pinterest.subtitle')}</p>
                  <div className="d-grid mt-3">
                    <AnchorLink className="btn btn-outline-primary btn-sm" href="#plans">{t('cta.learnMore')} <i className="bi-chevron-right small ms-1"></i></AnchorLink>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className="my-2">
              <div className="card card-sm card-transition shadow-sm">
                <div className="card-img-top" style={{ backgroundColor: '#FBEB24' }}>
                  <div className="m-4 text-center">
                    <div className="avatar avatar-lg rounded mx-auto d-flex justify-content-center flex-wrap p-2">
                      <StaticImage imgClassName="img-fluid" objectFit="contain" quality="30" src="../images/brands/pagesjaunes-icon.svg" alt={t('socialNetworks.pagesjaunes.title')} />
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="h4 card-title">{t('socialNetworks.pagesjaunes.title')}</div>
                  <p className="card-text text-body">{t('socialNetworks.pagesjaunes.subtitle')}</p>
                  <div className="d-grid mt-3">
                    <AnchorLink className="btn btn-outline-primary btn-sm" href="#plans">{t('cta.learnMore')} <i className="bi-chevron-right small ms-1"></i></AnchorLink>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className="my-2">
              <div className="card card-sm card-transition shadow-sm">
                <div className="card-img-top" style={{ backgroundColor: '#00AF87' }}>
                  <div className="m-4 text-center">
                    <div className="avatar avatar-lg rounded mx-auto d-flex justify-content-center flex-wrap p-2">
                      <StaticImage imgClassName="img-fluid" objectFit="contain" quality="30" src="../images/brands/tripadvisor-icon.svg" alt={t('socialNetworks.tripadvisor.title')} />
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="h4 card-title">{t('socialNetworks.tripadvisor.title')}</div>
                  <p className="card-text text-body">{t('socialNetworks.tripadvisor.subtitle')}</p>
                  <div className="d-grid mt-3">
                    <AnchorLink className="btn btn-outline-primary btn-sm" href="#plans">{t('cta.learnMore')} <i className="bi-chevron-right small ms-1"></i></AnchorLink>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className="my-2">
              <div className="card card-sm card-transition shadow-sm">
                <div className="card-img-top" style={{ backgroundColor: '#4d8f35' }}>
                  <div className="m-4 text-center">
                    <div className="avatar avatar-lg rounded mx-auto d-flex justify-content-center flex-wrap p-2">
                      <StaticImage imgClassName="img-fluid" objectFit="contain" quality="30" src="../images/brands/thefork-icon.svg" alt={t('socialNetworks.thefork.title')} />
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="h4 card-title">{t('socialNetworks.thefork.title')}</div>
                  <p className="card-text text-body">{t('socialNetworks.thefork.subtitle')}</p>
                  <div className="d-grid mt-3">
                    <AnchorLink className="btn btn-outline-primary btn-sm" href="#plans">{t('cta.learnMore')} <i className="bi-chevron-right small ms-1"></i></AnchorLink>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
          <div className="shape shape-bottom">
            <svg width="3000" height="500" viewBox="0 0 3000 500" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 500H3000V0L0 500Z" fill="#fff" />
            </svg>
          </div>
        </div>
        <div className="bg-light" id="simulator">
          <div className="container content-space-2 content-space-lg-3">
            <div className="w-md-75 w-lg-75 text-center mx-md-auto mb-5 mb-md-9">
              <h2>{t('simulator.title')}</h2>
              <p>{t('simulator.subtitle')}</p>
            </div>
            <CommunityManagementSimulatorSection data={data} plan={data.plan.frontmatter} service="community_management" />
          </div>
        </div>
        <div className="container content-space-2 content-space-lg-3">
          <div className="w-md-75 w-lg-75 text-center mx-md-auto mb-5 mb-md-9">
            <h2>{t('plans.title')}</h2>
            <p>{t('plans.subtitle')}</p>
          </div>
          <CommunityManagementPlansSection data={data} plan={data.plan.frontmatter} service="community_management" />
          <div className="text-center aos-init aos-animate" data-aos="fade-up">
            <div className="card card-info-link card-sm mb-5 mb-md-0">
              <div className="card-body">
                {t('cta.needMoreInfos')} <Link className="card-link ms-2" to="/contact/">{t('cta.contactUs')} <span className="bi-chevron-right small ms-1"></span></Link>
              </div>
            </div>
          </div>
        </div>
        {data.realisations.nodes.length ?
          <div className="bg-light">
            <div className="container content-space-2 content-space-lg-3">
              <div className="w-md-75 text-center mx-md-auto mb-5">
                <h2>{t('realisations.title', { title: data.site.siteMetadata.title })}</h2>
                <p>{t('realisations.subtitle', { title: data.site.siteMetadata.title })}</p>
              </div>
              <RealisationsSection data={data} realisations={data.realisations} service="home" />
              <div className="text-center aos-init aos-animate" data-aos="fade-up">
                <div className="card card-info-link card-sm mb-5 mb-md-0">
                  <div className="card-body">
                    <Link className="card-link ms-2" to="/realisations/">{t('realisations.button')} <span className="bi-chevron-right small ms-1"></span></Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          : null}
        {data.testimonials.nodes.length ?
          <div className="container content-space-2 content-space-lg-3">
            <div className="w-md-75 w-lg-75 text-center mx-md-auto mb-5 mb-md-9">
              <h2>{t('testimonials.title')}</h2>
            </div>
            <TestimonialsSection data={data} testimonials={data.testimonials} sectionStyle="1" service="home" />
            <div className="text-center aos-init aos-animate" data-aos="fade-up">
              <div className="card card-info-link card-sm mb-5 mb-md-0">
                <div className="card-body">
                  {t('cta.needMoreInfos')} <Link className="card-link ms-2" to="/contact/">{t('cta.contactUs')} <span className="bi-chevron-right small ms-1"></span></Link>
                </div>
              </div>
            </div>
          </div>
          : null}
        <div className="container content-space-2 content-space-b-lg-4">
          <div className="w-lg-75 mx-lg-auto">
            <div className="card card-sm overflow-hidden">
              <div className="card-body">
                <div className="row justify-content-md-start align-items-md-center text-center text-md-start">
                  <div className="col-md offset-md-3 mb-3 mb-md-0">
                    <h4 className="card-title">{t('cta.title')}</h4>
                  </div>
                  <div className="col-md-auto">
                    <Link className="btn btn-primary btn-transition" to="/contact/"><i className="bi bi-envelope me-1"></i>{t('cta.getStarted')}</Link>
                  </div>
                </div>
                <figure className="w-25 d-none d-md-block position-absolute top-0 start-0 mt-n2">
                  <svg viewBox="0 0 451 902" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.125" d="M0 82C203.8 82 369 247.2 369 451C369 654.8 203.8 820 0 820" stroke="url(#paint2_linear)" strokeWidth="164" strokeMiterlimit="10"></path>
                    <defs>
                      <linearGradient id="paint2_linear" x1="323.205" y1="785.242" x2="-97.6164" y2="56.3589" gradientUnits="userSpaceOnUse">
                        <stop offset="0" stopColor="white" stopOpacity="0"></stop>
                        <stop offset="1" stopColor="#377dff"></stop>
                      </linearGradient>
                    </defs>
                  </svg>
                </figure>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default OnePagePage

export const query = graphql`
  query ($language: String!) {
    site: site {
      siteMetadata {
        siteUrl
        title
      }
    }
    locales: allLocale(filter: {ns: {in: ["common", "header", "community-management", "plans", "simulator-community-management", "categories", "tools", "footer"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    realisations: allMdx(
      filter: {
        fileAbsolutePath: { regex: "/data/realisations/" },
        frontmatter: { services: { in: ["community-management"] }, active: { eq: true }, language: {eq: $language} }
      },
      sort: {fields: frontmatter___date, order: DESC}
      limit: 3
    ) {
      nodes {
        frontmatter {
          meta {
            title
            description
            keywords
          }
          title
          category
          tags
          technologies
          languages
          description
          client
          partners
          services
          image {
            card {
              childImageSharp {
                gatsbyImageData(width: 800)
              }
            }
            alt
          }
          home
          active
          language
          path
          date(formatString: "MMMM YYYY", locale: $language)
        }
        id
        timeToRead
      }
      totalCount
    }
    testimonials: allMdx(
      filter: {
        fileAbsolutePath: { regex: "/data/testimonials/" },
        frontmatter: { pages: {services: { in: ["community-management"] } }, active: { eq: true }, language: {eq: $language} }
      },
      sort: {fields: frontmatter___date, order: DESC}
    )
    {
      nodes {
        frontmatter {
          name
          rating
          from
          link
          pages {
            services
            realisations
          }
          active
          language
          date(formatString: "D MMMM YYYY", locale: $language)
        }
        id
        slug
        body
      }
    }
    plan: mdx(slug: {eq: "community_management"}, fileAbsolutePath: { regex: "/data/plans/" }) {
      frontmatter {
        price
        custom {
          social_medias {
            active
            google {
                active
                post_active
                story_active
                reel_active
                contest_active
                comment_active
                review_active
                message_active
            }
            facebook {
                active
                post_active
                story_active
                reel_active
                contest_active
                comment_active
                review_active
                message_active
            }
            instagram {
                active
                post_active
                story_active
                reel_active
                contest_active
                comment_active
                review_active
                message_active
            }
            linkedin {
                active
                post_active
                story_active
                reel_active
                contest_active
                comment_active
                review_active
                message_active
            }
            tiktok {
                active
                post_active
                story_active
                reel_active
                contest_active
                comment_active
                review_active
                message_active
            }
            pinterest {
                active
                post_active
                story_active
                reel_active
                contest_active
                comment_active
                review_active
                message_active
            }
            pagesjaunes {
                active
                post_active
                story_active
                reel_active
                contest_active
                comment_active
                review_active
                message_active
            }
            tripadvisor {
                active
                post_active
                story_active
                reel_active
                contest_active
                comment_active
                review_active
                message_active
            }
            thefork {
                active
                post_active
                story_active
                reel_active
                contest_active
                comment_active
                review_active
                message_active
            }
            included
            number_minimum
            number_maximum
            active_price
            additional_price
          }
          posts {
              active
              included
              number_minimum
              number_maximum
              active_price
              additional_price
              publish_active_price
              publish_additional_price
          }
          stories {
              active
              included
              number_minimum
              number_maximum
              active_price
              additional_price
              publish_active_price
              publish_additional_price
          }
          reels {
              active
              included
              number_minimum
              number_maximum
              active_price
              additional_price
              publish_active_price
              publish_additional_price
          }
          contests {
              active
              included
              number_minimum
              number_maximum
              active_price
              additional_price
              publish_active_price
              publish_additional_price
          }
          comments {
              active
              included
              active_price
              social_media_active_price
              social_media_additional_price
              post_price
              story_price
              reel_price
              contest_price
          }
          reviews {
              active
              included
              active_price
              social_media_active_price
              social_media_additional_price
          }
          messages {
              active
              included
              active_price
              social_media_active_price
              social_media_additional_price
          }
          languages {
              active
              included
              number_minimum
              number_maximum
              active_price
              additional_price
              post_price
              story_price
              reel_price
              contest_price
              comment_price
              review_price
              message_price
          }
          shootings {
              active
              included
              number_minimum
              number_maximum
              active_price
              additional_price
          }
        }
      }
      id
    }    
  }
`;
