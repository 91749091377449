import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation, useI18next} from 'gatsby-plugin-react-i18next';
import { StaticImage } from 'gatsby-plugin-image';
import Select from 'react-select';
import IntlTelInput from 'react-intl-tel-input';

const CommunityManagementSimulatorSection = ({ data, plan, service }) => {
  
  const {t} = useTranslation();
  const {language} = useI18next();

  // Social Medias
  const [socialMedias, setSocialMedias] = useState(['Facebook']);
  const [socialMediasSelected, setSocialMediasSelected] = useState([{ value: 'facebook', label: 'Facebook' }]);

  // Social Medias Options
  const optionsSocialMedias = [
    { value: 'google', label: 'Google' },
    { value: 'facebook', label: 'Facebook' },
    { value: 'instagram', label: 'Instagram' },
    { value: 'linkedin', label: 'LinkedIn' },
    { value: 'tiktok', label: 'TikTok' },
    { value: 'pinterest', label: 'Pinterest' },
    { value: 'pagesjaunes', label: 'PagesJaunes' },
    { value: 'tripadvisor', label: 'Tripadvisor' },
    { value: 'thefork', label: 'TheFork' }
  ]

  // Handle Change Social Medias
  const handleChangeSocialMedias = (events) => {
    var updatedList = [];
    var updatedListSelected = [];
    events.forEach(({label,value}) => {
      updatedList = [...updatedList, label];
      updatedListSelected = [...updatedListSelected, { value: value, label: label }];
    });
    setSocialMedias(updatedList);
    setSocialMediasSelected(updatedListSelected);
  };

  // Posts
  const [postsSelected, setPostsSelected] = useState({ label: 1, value: 1 });

  // Posts Options
  var optionsPostsList = [];
  for (var i1 = plan.custom.posts.number_minimum; i1 <= plan.custom.posts.number_maximum; i1++) {
    optionsPostsList.push({ label: i1, value: i1 })
  }
  const [optionsPosts] = useState(optionsPostsList);

  // Handle Change Posts
  const handleChangePosts = (obj) => {
    setPostsSelected(obj);
  };

  // Stories
  const [storiesSelected, setStoriesSelected] = useState({ label: 0, value: 0 });

  // Stories Options
  var optionsStoriesList = [];
  for (var i2 = plan.custom.stories.number_minimum; i2 <= plan.custom.stories.number_maximum; i2++) {
    optionsStoriesList.push({ label: i2, value: i2 })
  }
  const [optionsStories] = useState(optionsStoriesList);

  // Handle Change Stories
  const handleChangeStories = (obj) => {
    setStoriesSelected(obj);
  };

  // Reels
  const [reelsSelected, setReelsSelected] = useState({ label: 0, value: 0 });

  // Reels Options
  var optionsReelsList = [];
  for (var i3 = plan.custom.reels.number_minimum; i3 <= plan.custom.reels.number_maximum; i3++) {
    optionsReelsList.push({ label: i3, value: i3 })
  }
  const [optionsReels] = useState(optionsReelsList);

  // Handle Change Reels
  const handleChangeReels = (obj) => {
    setReelsSelected(obj);
  };

  // Contests
  const [contestsSelected, setContestsSelected] = useState({ label: 0, value: 0 });

  // Contests Options
  var optionsContestsList = [];
  for (var i4 = plan.custom.contests.number_minimum; i4 <= plan.custom.contests.number_maximum; i4++) {
    optionsContestsList.push({ label: i4, value: i4 })
  }
  const [optionsContests] = useState(optionsContestsList);

  // Handle Change Contests
  const handleChangeContests = (obj) => {
    setContestsSelected(obj);
  };

  // Comments
  const [commentsSelected, setCommentsSelected] = useState({ label: t('simulator.sentence.commentsOff'), value: 0 });

  // Comments Options
  const optionsComments = [
    { value: 0, label: t('simulator.sentence.commentsOff') },
    { value: 1, label: t('simulator.sentence.commentsOn') }
  ]

  // Handle Change Comments
  const handleChangeComments = (obj) => {
    setCommentsSelected(obj);
  };

  // Reviews
  const [reviewsSelected, setReviewsSelected] = useState({ label: t('simulator.sentence.reviewsOff'), value: 0 });

  // Reviews Options
  const optionsReviews = [
    { value: 0, label: t('simulator.sentence.reviewsOff') },
    { value: 1, label: t('simulator.sentence.reviewsOn') }
  ]

  // Handle Change Reviews
  const handleChangeReviews = (obj) => {
    setReviewsSelected(obj);
  };

  // Messages
  const [messagesSelected, setMessagesSelected] = useState({ label: t('simulator.sentence.messagesOff'), value: 0 });

  // Messages Options
  const optionsMessages = [
    { value: 0, label: t('simulator.sentence.messagesOff') },
    { value: 1, label: t('simulator.sentence.messagesOn') }
  ]

  // Handle Change Messages
  const handleChangeMessages = (obj) => {
    setMessagesSelected(obj);
  };

  // Languages
  const [languagesSelected, setLanguagesSelected] = useState({ label: plan.custom.languages.included, value: plan.custom.languages.included });

  // Languages Options
  var optionsLanguagesList = [];
  for (var i5 = plan.custom.languages.number_minimum; i5 <= plan.custom.languages.number_maximum; i5++) {
    optionsLanguagesList.push({ label: i5, value: i5 })
  }
  const [optionsLanguages] = useState(optionsLanguagesList);

  // Handle Change Languages
  const handleChangeLanguages = (obj) => {
    setLanguagesSelected(obj);
  };

  // Shootings
  const [shootingsSelected, setShootingsSelected] = useState({ label: plan.custom.shootings.included, value: plan.custom.shootings.included });

  // Shootings Options
  var optionsShootingsList = [];
  for (var i6 = plan.custom.shootings.number_minimum; i6 <= plan.custom.shootings.number_maximum; i6++) {
    optionsShootingsList.push({ label: i6, value: i6 })
  }
  const [optionsShootings] = useState(optionsShootingsList);

  // Handle Change Shootings
  const handleChangeShootings = (obj) => {
    setShootingsSelected(obj);
  };

  // Simulator
  const [price, setPrice] = useState(null);

  const [socialMediaPosts, setSocialMediaPosts] = useState(1);
  const [socialMediaStories, setSocialMediaStories] = useState(1);
  const [socialMediaReels, setSocialMediaReels] = useState(1);
  const [socialMediaContests, setSocialMediaContests] = useState(1);
  const [socialMediaComments, setSocialMediaComments] = useState(1);
  const [socialMediaReviews, setSocialMediaReviews] = useState(1);
  const [socialMediaMessages, setSocialMediaMessages] = useState(1);

  // Update Simulator
  const updateSimulator = value => {

    // Plan
    var price = plan.price;
  
    // Social Medias
    if(plan.custom.social_medias.active === true) {
      var socialMediaPostsActive = 0;
      var socialMediaStoriesActive = 0;
      var socialMediaReelsActive = 0;
      var socialMediaContestsActive = 0;
      var socialMediaCommentsActive = 0;
      var socialMediaReviewsActive = 0;
      var socialMediaMessagesActive = 0;
      socialMediasSelected.forEach(social_media => {
        if(plan.custom.social_medias[social_media.value]){
          if(plan.custom.social_medias[social_media.value].post_active === true){
            socialMediaPostsActive++;
          }
          if(plan.custom.social_medias[social_media.value].story_active === true){
            socialMediaStoriesActive++;
          }
          if(plan.custom.social_medias[social_media.value].reel_active === true){
            socialMediaReelsActive++;
          }
          if(plan.custom.social_medias[social_media.value].contest_active === true){
            socialMediaContestsActive++;
          }
          if(plan.custom.social_medias[social_media.value].comment_active === true){
            socialMediaCommentsActive++;
          }
          if(plan.custom.social_medias[social_media.value].review_active === true){
            socialMediaReviewsActive++;
          }
          if(plan.custom.social_medias[social_media.value].message_active === true){
            socialMediaMessagesActive++;
          }
        }
      });
      setSocialMediaPosts(socialMediaPostsActive);
      setSocialMediaStories(socialMediaStoriesActive);
      setSocialMediaReels(socialMediaReelsActive);
      setSocialMediaContests(socialMediaContestsActive);
      setSocialMediaComments(socialMediaCommentsActive);
      setSocialMediaReviews(socialMediaReviewsActive);
      setSocialMediaMessages(socialMediaMessagesActive);
    }

    // Social Medias
    if(plan.custom.social_medias.active === true && socialMediasSelected.length > 0) {
      price += plan.custom.social_medias.active_price;
      if(socialMediasSelected.length > 1){
        price += plan.custom.social_medias.additional_price * ( socialMediasSelected.length - 1 );
      }
    }

    // Posts
    if(plan.custom.posts.active === true && postsSelected.value > 0 && socialMediaPostsActive > 0) {
      // Creation
      if(plan.custom.posts.included === 0){
        price += plan.custom.posts.active_price;
        if(postsSelected.value > 1){
          price += plan.custom.posts.additional_price * ( postsSelected.value - 1 );
        }
      }else{
        if(postsSelected.value > plan.custom.posts.included) {
          price += plan.custom.posts.active_price;
          if(postsSelected.value > plan.custom.posts.included){
            price += plan.custom.posts.additional_price * ( postsSelected.value - plan.custom.posts.included );
          }
        }
      }
      // Publish
      if(plan.custom.posts.included === 0){
        price += plan.custom.posts.publish_active_price;
        if(socialMediaPostsActive > 1){
          price += plan.custom.posts.publish_additional_price * postsSelected.value * ( socialMediaPostsActive - 1 );
        }
      }else{
        if(postsSelected.value > plan.custom.posts.included) {
          price += plan.custom.posts.publish_active_price;
          if(socialMediaPostsActive > 1){
            price += plan.custom.posts.publish_additional_price * ( postsSelected.value - plan.custom.posts.included ) * ( socialMediaPostsActive - 1 );
          }
        }
      }
    }

    // Stories
    if(plan.custom.stories.active === true && storiesSelected.value > 0 && socialMediaStoriesActive > 0) {
      // Creation
      if(plan.custom.stories.included === 0){
        price += plan.custom.stories.active_price;
        if(storiesSelected.value > 1){
          price += plan.custom.stories.additional_price * ( storiesSelected.value - 1 );
        }
      }else{
        if(storiesSelected.value > plan.custom.stories.included) {
          price += plan.custom.stories.active_price;
          if(storiesSelected.value > plan.custom.stories.included){
            price += plan.custom.stories.additional_price * ( storiesSelected.value - plan.custom.stories.included );
          }
        }
      }
      // Publish
      if(plan.custom.stories.included === 0){
        price += plan.custom.stories.publish_active_price;
        if(socialMediaStoriesActive > 1){
          price += plan.custom.stories.publish_additional_price * storiesSelected.value * ( socialMediaStoriesActive - 1 );
        }
      }else{
        if(storiesSelected.value > plan.custom.stories.included) {
          price += plan.custom.stories.publish_active_price;
          if(socialMediaStoriesActive > 1){
            price += plan.custom.stories.publish_additional_price * ( storiesSelected.value - plan.custom.stories.included ) * ( socialMediaStoriesActive - 1 );
          }
        }
      }
    }

    // Reels
    if(plan.custom.reels.active === true && reelsSelected.value > 0 && socialMediaReelsActive > 0) {
      // Creation
      if(plan.custom.reels.included === 0){
        price += plan.custom.reels.active_price;
        if(reelsSelected.value > 1){
          price += plan.custom.reels.additional_price * ( reelsSelected.value - 1 );
        }
      }else{
        if(reelsSelected.value > plan.custom.reels.included) {
          price += plan.custom.reels.active_price;
          if(reelsSelected.value > plan.custom.reels.included){
            price += plan.custom.reels.additional_price * ( reelsSelected.value - plan.custom.reels.included );
          }
        }
      }
      // Publish
      if(plan.custom.reels.included === 0){
        price += plan.custom.reels.publish_active_price;
        if(socialMediaReelsActive > 1){
          price += plan.custom.reels.publish_additional_price * reelsSelected.value * ( socialMediaReelsActive - 1 );
        }
      }else{
        if(reelsSelected.value > plan.custom.reels.included) {
          price += plan.custom.reels.publish_active_price;
          if(socialMediaReelsActive > 1){
            price += plan.custom.reels.publish_additional_price * ( reelsSelected.value - plan.custom.reels.included ) * ( socialMediaReelsActive - 1 );
          }
        }
      }
    }

    // Contests
    if(plan.custom.contests.active === true && contestsSelected.value > 0 && socialMediaContestsActive > 0) {
      // Creation
      if(plan.custom.contests.included === 0){
        price += plan.custom.contests.active_price;
        if(contestsSelected.value > 1){
          price += plan.custom.contests.additional_price * ( contestsSelected.value - 1 );
        }
      }else{
        if(contestsSelected.value > plan.custom.contests.included) {
          price += plan.custom.contests.active_price;
          if(contestsSelected.value > plan.custom.contests.included){
            price += plan.custom.contests.additional_price * ( contestsSelected.value - plan.custom.contests.included );
          }
        }
      }
      // Publish
      if(plan.custom.contests.included === 0){
        price += plan.custom.contests.publish_active_price;
        if(socialMediaContestsActive > 1){
          price += plan.custom.contests.publish_additional_price * contestsSelected.value * ( socialMediaContestsActive - 1 );
        }
      }else{
        if(contestsSelected.value > plan.custom.contests.included) {
          price += plan.custom.contests.publish_active_price;
          if(socialMediaContestsActive > 1){
            price += plan.custom.contests.publish_additional_price * ( contestsSelected.value - plan.custom.contests.included ) * ( socialMediaContestsActive - 1 );
          }
        }
      }
    }

    // Comments
    if(plan.custom.comments.active === true && plan.custom.comments.included === false && commentsSelected.value === 1 && socialMediaCommentsActive > 0) {
      price += plan.custom.comments.active_price;
      // Social Media
      price += plan.custom.comments.social_media_active_price;
      if(socialMediaCommentsActive > 1){
        price += plan.custom.comments.social_media_additional_price * ( socialMediaCommentsActive - 1 );
      }
      // Posts
      if(postsSelected.value > 0 && socialMediaPostsActive > 0) {
        price += plan.custom.comments.post_price * postsSelected.value * socialMediaPostsActive;
      }
      // Stories
      if(storiesSelected.value > 0 && socialMediaPostsActive > 0) {
        price += plan.custom.comments.story_price * storiesSelected.value * socialMediaPostsActive;
      }
      // Reels
      if(reelsSelected.value > 0 && socialMediaReelsActive > 0) {
        price += plan.custom.comments.reel_price * reelsSelected.value * socialMediaReelsActive;
      }
      // Contests
      if(contestsSelected.value > 0 && socialMediaContestsActive > 0) {
        price += plan.custom.comments.contest_price * contestsSelected.value * socialMediaContestsActive;
      }
    }

    // Reviews
    if(plan.custom.reviews.active === true && plan.custom.reviews.included === false && reviewsSelected.value === 1 && socialMediaReviewsActive > 0) {
      price += plan.custom.reviews.active_price;
      // Social Media
      price += plan.custom.reviews.social_media_active_price;
      if(socialMediaReviewsActive > 1){
        price += plan.custom.reviews.social_media_additional_price * ( socialMediaReviewsActive - 1 );
      }
    }

    // Messages
    if(plan.custom.messages.active === true && plan.custom.messages.included === false && messagesSelected.value === 1 && socialMediaMessagesActive > 0) {
      price += plan.custom.messages.active_price;
      // Social Media
      price += plan.custom.messages.social_media_active_price;
      if(socialMediaMessagesActive > 1){
        price += plan.custom.messages.social_media_additional_price * ( socialMediaMessagesActive - 1 );
      }
    }

    // Languages
    if(plan.custom.languages.active === true) {
      if(languagesSelected.value > plan.custom.languages.included) {
        price += plan.custom.languages.active_price + ( plan.custom.languages.additional_price * ( languagesSelected.value - plan.custom.languages.included ) );
        // Stories 
        if(socialMediaStoriesActive > 1){
          price += plan.custom.languages.story_price * storiesSelected.value * socialMediaStoriesActive * ( languagesSelected.value - plan.custom.languages.included );
        }
        // Reels 
        if(socialMediaReelsActive > 1){
          price += plan.custom.languages.reel_price * reelsSelected.value * socialMediaReelsActive * ( languagesSelected.value - plan.custom.languages.included );
        }
        // Contests 
        if(socialMediaContestsActive > 1){
          price += plan.custom.languages.contest_price * contestsSelected.value * socialMediaContestsActive * ( languagesSelected.value - plan.custom.languages.included );
        }
        // Comments 
        if(socialMediaCommentsActive > 1){
          price += plan.custom.languages.comment_price * commentsSelected.value * socialMediaCommentsActive * ( languagesSelected.value - plan.custom.languages.included );
        }
        // Reviews 
        if(socialMediaReviewsActive > 1){
          price += plan.custom.languages.review_price * reviewsSelected.value * socialMediaReviewsActive * ( languagesSelected.value - plan.custom.languages.included );
        }
        // Messages 
        if(socialMediaMessagesActive > 1){
          price += plan.custom.languages.message_price * messagesSelected.value * socialMediaMessagesActive * ( languagesSelected.value - plan.custom.languages.included );
        }
      }
    }
    
    // Languages
    if(plan.custom.languages.active === true && languagesSelected.value > 1) {
      if(plan.custom.languages.included === 0){
        price += plan.custom.languages.active_price + ( plan.custom.languages.additional_price * ( languagesSelected.value - 1 ) );
        if(languagesSelected.value > 1){
          // Stories 
          if(socialMediaStoriesActive > 1){
            price += plan.custom.languages.story_price * storiesSelected.value * socialMediaStoriesActive * ( languagesSelected.value - 1 );
          }
          // Reels 
          if(socialMediaReelsActive > 1){
            price += plan.custom.languages.reel_price * reelsSelected.value * socialMediaReelsActive * ( languagesSelected.value - 1 );
          }
          // Contests 
          if(socialMediaContestsActive > 1){
            price += plan.custom.languages.contest_price * contestsSelected.value * socialMediaContestsActive * ( languagesSelected.value - 1 );
          }
          // Comments 
          if(socialMediaCommentsActive > 1){
            price += plan.custom.languages.comment_price * commentsSelected.value * socialMediaCommentsActive * ( languagesSelected.value - 1 );
          }
          // Reviews 
          if(socialMediaReviewsActive > 1){
            price += plan.custom.languages.review_price * reviewsSelected.value * socialMediaReviewsActive * ( languagesSelected.value - 1 );
          }
          // Messages 
          if(socialMediaMessagesActive > 1){
            price += plan.custom.languages.message_price * messagesSelected.value * socialMediaMessagesActive * ( languagesSelected.value - 1 );
          }
        }
      }else{
        if(languagesSelected.value > plan.custom.languages.included) {
          price += plan.custom.languages.active_price + ( plan.custom.languages.additional_price * ( languagesSelected.value - plan.custom.languages.included ) );
          // Stories 
          if(socialMediaStoriesActive > 1){
            price += plan.custom.languages.story_price * storiesSelected.value * socialMediaStoriesActive * ( languagesSelected.value - plan.custom.languages.included );
          }
          // Reels 
          if(socialMediaReelsActive > 1){
            price += plan.custom.languages.reel_price * reelsSelected.value * socialMediaReelsActive * ( languagesSelected.value - plan.custom.languages.included );
          }
          // Contests 
          if(socialMediaContestsActive > 1){
            price += plan.custom.languages.contest_price * contestsSelected.value * socialMediaContestsActive * ( languagesSelected.value - plan.custom.languages.included );
          }
          // Comments 
          if(socialMediaCommentsActive > 1){
            price += plan.custom.languages.comment_price * commentsSelected.value * socialMediaCommentsActive * ( languagesSelected.value - plan.custom.languages.included );
          }
          // Reviews 
          if(socialMediaReviewsActive > 1){
            price += plan.custom.languages.review_price * reviewsSelected.value * socialMediaReviewsActive * ( languagesSelected.value - plan.custom.languages.included );
          }
          // Messages 
          if(socialMediaMessagesActive > 1){
            price += plan.custom.languages.message_price * messagesSelected.value * socialMediaMessagesActive * ( languagesSelected.value - plan.custom.languages.included );
          }
        }
      }
    }

    // Shootings
    if(plan.custom.shootings.active === true) {
      if(shootingsSelected.value > plan.custom.shootings.included) {
        price += plan.custom.shootings.active_price;
        if(shootingsSelected.value > 1){
          price += plan.custom.shootings.additional_price * ( shootingsSelected.value - 1 );
        }
      }
    }

    setPrice(price);

    // Message
    var message = t('simulator.contact.message.service');
    message += '<br>';
    message += t('simulator.contact.message.iNeed', { social_medias: socialMedias});
    if(plan.custom.posts.active === true) {
      message += '<br>';
      message +=  t('simulator.contact.message.posts', { count: postsSelected.value });
    }
    if(plan.custom.stories.active === true) {
      message += '<br>';
      message +=  t('simulator.contact.message.stories', { count: storiesSelected.value });
    }
    if(plan.custom.reels.active === true) {
      message += '<br>';
      message +=  t('simulator.contact.message.reels', { count: reelsSelected.value });
    }
    if(plan.custom.contests.active === true) {
      message += '<br>';
      message +=  t('simulator.contact.message.contests', { count: contestsSelected.value });
    }
    if(plan.custom.comments.active === true) {
      message += '<br>';
      message +=  t('simulator.contact.message.comments', { count: commentsSelected.value });
    }
    if(plan.custom.reviews.active === true) {
      message += '<br>';
      message +=  t('simulator.contact.message.reviews', { count: reviewsSelected.value });
    }
    if(plan.custom.messages.active === true) {
      message += '<br>';
      message +=  t('simulator.contact.message.messages', { count: messagesSelected.value });
    }
    if(plan.custom.languages.active === true) {
      message += '<br>';
      message +=  t('simulator.contact.message.languages', { count: languagesSelected.value });
    }
    if(plan.custom.shootings.active === true) {
      message += '<br>';
      message +=  t('simulator.contact.message.shootings', { count: shootingsSelected.value });
    }
    message += '<br>';
    message +=  t('simulator.contact.message.price', { service_price: price });
    setValue("message",message);

  }

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    // Update the document title using the browser API
    updateSimulator();
  });

  const selectCustomStyles = {
    control: (provided, state) => ({
      ...provided,
      boxShadow: "none",
      border:  "none",
      color: '#377dff',
    }),
    menu: (provided, state) => ({
      ...provided,
      border: "none",
      boxShadow: "none"
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: '#377dff',
    }),
    option: (provided, state) => ({
       ...provided,
       fontSize: "1rem"
    }),
  }

  const selectTheme = theme => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary25: "#f3f3f3",
      primary: "#377dff",
      danger: '#ed4c78',
      dangerLight: '#f5ca99',
      neutral0: '#ffffff',
      neutral5: '#f7faff',
      neutral10: '#f8fafd',
      neutral20: "rgba(33, 50, 91, 0.1)",
      neutral30: '#bdc5d1',
      neutral40: '#97a4af',
      neutral50: '#8c98a4',
      neutral60: '#677788',
      neutral70: '#71869d',
      neutral80: '#1e2022',
      neutral90: '#21325b',
    },
    // Other options you can use
    border: 0,
    borderRadius: 4,
    boxShadow: 'none',
  });

  // Telephone
  const handleChangeTelephone = (status, phoneNumber, country) => {
    setValue("telephone",phoneNumber)
  };

  const handleBlurTelephone = (status, phoneNumber, country) => {
    setValue("telephone",phoneNumber)
  };

  // Submit
  const [submitted, setSubmitted] = useState(false);
  const {
    register,
    handleSubmit,
    control,
    setValue,
    setError,
    reset,
    formState: { errors, isSubmitting }
  } = useForm();

  const onSubmit = async data => {
    await fetch(process.env.GATSBY_API_URL + 'messages', {
      method: "POST",
      mode: 'cors',
      headers: {
        "Content-Type": "application/json",
        "api-key": process.env.GATSBY_API_KEY,
        "api-secret-key": process.env.GATSBY_API_SECRET_KEY,
        "form-uuid": process.env.GATSBY_FORM_SIMULATOR_UUID,
        "language-code": language,
      },
      body: JSON.stringify(data),
    })
    .then(response => response.json())
    .then(result => {
      if(result.error === false){
        setSubmitted(true);
        reset();
      }else if(result.error === true){
        setError(
          "submit",
          "submitError",
        );
      }
    })
    .catch(error => {
      setError(
        "submit",
        "submitError",
        `${t('simulator.contact.form.error')} ${error.message}`
      );  
    });
  };

  const showSubmitError = msg => <p className="text-danger">{t('simulator.contact.form.error')}</p>

  const showSubmitted = (
    <div className="m-4 text-center">
      <h3>{t('simulator.contact.form.sent.title')}</h3>
      <StaticImage className="img-fluid w-50" quality="30" src="../../../images/illustrations/la-small-agency-message.svg" alt="Image Description" />
      <p>{t('simulator.contact.form.sent.subtitle')}</p>
    </div>
  );

  const showForm = (
    <>
      <form onSubmit={handleSubmit(onSubmit)} method="post">  
        <div className="m-4">
          <div className="row">
            <div className="col-md-6">
              <div className="form-group mb-3">
                <input
                  type="text"
                  name="first_name"
                  id="first_name"
                  className="form-control form-control-sm"
                  placeholder={t('simulator.contact.form.firstName.placeholder')}
                  maxLength="100"
                  {...register("first_name", { required: true })}
                  disabled={isSubmitting}
                />
                {errors.first_name && <div className="text-danger">{t('simulator.contact.form.firstName.required')}</div>}
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group mb-3">
                <input
                  type="text"
                  name="last_name"
                  id="last_name"
                  className="form-control form-control-sm"
                  placeholder={t('simulator.contact.form.lastName.placeholder')}
                  maxLength="100"
                  {...register("last_name", { required: true })}
                  disabled={isSubmitting}
                />
                {errors.last_name && <div className="text-danger">{t('simulator.contact.form.lastName.required')}</div>}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group mb-3">
                <input
                  type="email"
                  name="email"
                  id="email"
                  className="form-control form-control-sm"
                  placeholder={t('simulator.contact.form.email.placeholder')}
                  maxLength="255"
                  {...register("email", { required: true })}
                  disabled={isSubmitting}
                />
                {errors.email && <div className="text-danger">{t('simulator.contact.form.email.required')}</div>}
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group mb-3">
                <Controller
                  name="telephone"
                  id="telephone"
                  maxLength="20"
                  rules={{ required: false }}
                  disabled={isSubmitting}
                  control={control}
                  render={({ field }) => (
                    <IntlTelInput
                      inputClassName="form-control form-control-sm"
                      nationalMode={false}
                      autoPlaceholder={true}
                      autoHideDialCode={false}
                      format
                      formatOnInit={true}
                      preferredCountries={["fr", "gb", "ru"]}
                      onPhoneNumberChange={handleChangeTelephone}
                      onPhoneNumberBlur={handleBlurTelephone}
                      placeholder={t('simulator.contact.form.telephone.placeholder')}
                    />
                  )}
                />
                {errors.telephone && <div className="text-danger">{t('simulator.contact.form.telephone.required')}</div>}
              </div>
            </div>
            <textarea
              className="d-none"
              name="message"
              id="message"
              maxLength="1000"
              {...register("message", { required: false })}
              disabled={isSubmitting}
            >
            </textarea>
          </div>
        </div>
        <div className="text-center">
          {errors && errors.submit && showSubmitError(errors.submit.message)}
        </div>
        <button type="submit" className="card-footer card-link bg-light btn btn-ghost-primary text-center w-100" disabled={isSubmitting}>{t('simulator.contact.form.send')}<span className="bi-send small ms-1"></span></button>
      </form>
    </>
  );

  return (
    <div>
      {service === 'community_management' ?    
        <div className="row gx-0 align-items-md-start" id="plans">
          <div className="col-md-8 mb-3 mb-md-0">
            <div className="card card-lg zi-2" data-aos="fade-up">
              <div className="card-body">
                <div className="d-flex flex-wrap lead text-dark">
                  <div className="">
                    <span>{t('simulator.sentence.iNeed')}</span>
                    {plan.custom.social_medias.active === true &&
                      <span>
                        <Controller
                          name="social_medias"
                          id="social-medias"
                          rules={{ required: false }}
                          disabled={isSubmitting}
                          control={control}
                          render={({ field }) => (
                            <Select
                              {...field}
                              className="d-inline-block"
                              placeholder=".........."
                              noOptionsMessage={() => t('simulator.sentence.socialMediasNoOptionsMessage')}
                              isMulti
                              isClearable={false}
                              cacheOptions
                              defaultOptions
                              value={socialMediasSelected}
                              options={optionsSocialMedias}
                              onChange={handleChangeSocialMedias}
                              styles={selectCustomStyles}
                              theme={selectTheme}
                              components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                            />
                          )}
                        />
                      </span>
                    }
                    {socialMediasSelected.length > 0 &&
                      <>
                        {socialMediaPosts > 0 && plan.custom.posts.active === true &&
                          <span>
                            {plan.custom.posts.number_maximum === 1 &&
                              <span> {t('simulator.sentence.post')}</span>
                            }
                            {plan.custom.posts.number_maximum > 1 &&
                              <span>
                                <Controller
                                  name="posts"
                                  id="posts"
                                  rules={{ required: false }}
                                  disabled={isSubmitting}
                                  control={control}
                                  render={({ field }) => (
                                    <Select
                                      {...field}
                                      className="d-inline-block"
                                      cacheOptions
                                      defaultOptions
                                      value={postsSelected}
                                      options={optionsPosts}
                                      onChange={handleChangePosts}
                                      styles={selectCustomStyles}
                                      theme={selectTheme}
                                      components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                                    />
                                  )}
                                />
                                <span data-bs-toggle="popover" data-bs-container="body" data-bs-placement="top" data-bs-html="true" data-bs-trigger="hover" data-bs-content={t('simulator.sentence.postsPopoverContent')}>
                                  <span>{t('simulator.sentence.posts', { count: postsSelected.value })}</span>
                                </span>
                              </span>
                            }
                          </span>
                        }
                        {socialMediaStories > 0 && plan.custom.stories.active === true &&
                          <span>
                            <Controller
                              name="stories"
                              id="stories"
                              rules={{ required: false }}
                              disabled={isSubmitting}
                              control={control}
                              render={({ field }) => (
                                <Select
                                  {...field}
                                  className="d-inline-block"
                                  cacheOptions
                                  defaultOptions
                                  value={storiesSelected}
                                  options={optionsStories}
                                  onChange={handleChangeStories}
                                  styles={selectCustomStyles}
                                  theme={selectTheme}
                                  components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                                />
                              )}
                            />
                            <span data-bs-toggle="popover" data-bs-container="body" data-bs-placement="top" data-bs-html="true" data-bs-trigger="hover" data-bs-content={t('simulator.sentence.storiesPopoverContent')}>
                              <span>{t('simulator.sentence.stories', { count: storiesSelected.value })}</span>
                            </span>
                          </span>
                        }
                        {socialMediaReels > 0 && plan.custom.reels.active === true &&
                          <span>
                            <Controller
                              name="reels"
                              id="reels"
                              rules={{ required: false }}
                              disabled={isSubmitting}
                              control={control}
                              render={({ field }) => (
                                <Select
                                  {...field}
                                  className="d-inline-block"
                                  cacheOptions
                                  defaultOptions
                                  value={reelsSelected}
                                  options={optionsReels}
                                  onChange={handleChangeReels}
                                  styles={selectCustomStyles}
                                  theme={selectTheme}
                                  components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                                />
                              )}
                            />
                            <span data-bs-toggle="popover" data-bs-container="body" data-bs-placement="top" data-bs-html="true" data-bs-trigger="hover" data-bs-content={t('simulator.sentence.reelsPopoverContent')}>
                              <span>{t('simulator.sentence.reels', { count: reelsSelected.value })}</span>
                            </span>
                          </span>
                        }
                        {socialMediaContests > 0 && plan.custom.contests.active === true &&
                          <span>
                            <Controller
                              name="contests"
                              id="contests"
                              rules={{ required: false }}
                              disabled={isSubmitting}
                              control={control}
                              render={({ field }) => (
                                <Select
                                  {...field}
                                  className="d-inline-block"
                                  cacheOptions
                                  defaultOptions
                                  value={contestsSelected}
                                  options={optionsContests}
                                  onChange={handleChangeContests}
                                  styles={selectCustomStyles}
                                  theme={selectTheme}
                                  components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                                />
                              )}
                            />
                            <span data-bs-toggle="popover" data-bs-container="body" data-bs-placement="top" data-bs-html="true" data-bs-trigger="hover" data-bs-content={t('simulator.sentence.contestsPopoverContent')}>
                              <span>{t('simulator.sentence.contests', { count: contestsSelected.value })}</span>
                            </span>
                          </span>
                        }
                        {socialMediaComments > 0 && plan.custom.comments.active === true &&
                          <span>
                            <Controller
                              name="comments"
                              id="comments"
                              rules={{ required: false }}
                              disabled={isSubmitting}
                              control={control}
                              render={({ field }) => (
                                <Select
                                  {...field}
                                  className="d-inline-block"
                                  cacheOptions
                                  defaultOptions
                                  value={commentsSelected}
                                  options={optionsComments}
                                  onChange={handleChangeComments}
                                  styles={selectCustomStyles}
                                  theme={selectTheme}
                                  components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                                />
                              )}
                            />
                            <span data-bs-toggle="popover" data-bs-container="body" data-bs-placement="top" data-bs-html="true" data-bs-trigger="hover" data-bs-content={t('simulator.sentence.commentsPopoverContent')}>
                              <span>{t('simulator.sentence.comments')}</span>
                            </span>
                          </span>
                        }
                        {socialMediaReviews > 0 && plan.custom.reviews.active === true &&
                          <span>
                            <Controller
                              name="reviews"
                              id="reviews"
                              rules={{ required: false }}
                              disabled={isSubmitting}
                              control={control}
                              render={({ field }) => (
                                <Select
                                  {...field}
                                  className="d-inline-block"
                                  cacheOptions
                                  defaultOptions
                                  value={reviewsSelected}
                                  options={optionsReviews}
                                  onChange={handleChangeReviews}
                                  styles={selectCustomStyles}
                                  theme={selectTheme}
                                  components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                                />
                              )}
                            />
                            <span data-bs-toggle="popover" data-bs-container="body" data-bs-placement="top" data-bs-html="true" data-bs-trigger="hover" data-bs-content={t('simulator.sentence.reviewsPopoverContent')}>
                              <span>{t('simulator.sentence.reviews')}</span>
                            </span>
                          </span>
                        }
                        {socialMediaMessages > 0 && plan.custom.messages.active === true &&
                          <span>
                            <Controller
                              name="messages"
                              id="messages"
                              rules={{ required: false }}
                              disabled={isSubmitting}
                              control={control}
                              render={({ field }) => (
                                <Select
                                  {...field}
                                  className="d-inline-block"
                                  cacheOptions
                                  defaultOptions
                                  value={messagesSelected}
                                  options={optionsMessages}
                                  onChange={handleChangeMessages}
                                  styles={selectCustomStyles}
                                  theme={selectTheme}
                                  components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                                />
                              )}
                            />
                            <span data-bs-toggle="popover" data-bs-container="body" data-bs-placement="top" data-bs-html="true" data-bs-trigger="hover" data-bs-content={t('simulator.sentence.messagesPopoverContent')}>
                              <span>{t('simulator.sentence.messages')}</span>
                            </span>
                          </span>
                        }
                        {plan.custom.languages.active === true &&
                          <span>
                            <Controller
                              name="languages"
                              id="languages"
                              rules={{ required: false }}
                              disabled={isSubmitting}
                              control={control}
                              render={({ field }) => (
                                <Select
                                  {...field}
                                  className="d-inline-block"
                                  cacheOptions
                                  defaultOptions
                                  value={languagesSelected}
                                  options={optionsLanguages}
                                  onChange={handleChangeLanguages}
                                  styles={selectCustomStyles}
                                  theme={selectTheme}
                                  components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                                />
                              )}
                            />
                            <span>{t('simulator.sentence.languages', { count: languagesSelected.value })}</span>
                          </span>
                        }
                        {plan.custom.shootings.active === true &&
                          <span>
                            <Controller
                              name="shootings"
                              id="shootings"
                              rules={{ required: false }}
                              disabled={isSubmitting}
                              control={control}
                              render={({ field }) => (
                                <Select
                                  {...field}
                                  className="d-inline-block"
                                  cacheOptions
                                  defaultOptions
                                  value={shootingsSelected}
                                  options={optionsShootings}
                                  onChange={handleChangeShootings}
                                  styles={selectCustomStyles}
                                  theme={selectTheme}
                                  components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                                />
                              )}
                            />
                            <span data-bs-toggle="popover" data-bs-container="body" data-bs-placement="top" data-bs-html="true" data-bs-trigger="hover" data-bs-content={t('simulator.sentence.shootingsPopoverContent')}>
                              <span>{t('simulator.sentence.shootings', { count: shootingsSelected.value })}</span>
                            </span>
                          </span>
                        }
                      </>
                    } 
                  </div>
                </div>
              </div>
              <hr className="mt-0 mb-0"/>
              <div>{submitted ? showSubmitted : showForm}</div>
            </div>
          </div>
          {socialMediasSelected.length > 0 &&
            <div className="col-md-4">
              <div className="card card-lg bg-dark ms-md-n2" data-aos="fade-up" data-aos-delay="200">
                <div className="card-body text-center">
                  <div className="card-text text-white-70">{t('simulator.from')}</div>
                  <div className="mb-3" data-bs-toggle="popover" data-bs-container="body" data-bs-placement="top" data-bs-html="true" data-bs-trigger="hover" data-bs-content={t('simulator.allTaxesExcluded')}>
                    {price &&
                      <div className="text-white"><span className="display-1">{t('simulator.price', { service_price: price })}</span><span>{t('simulator.monthly')}</span></div>
                    }
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
      : null}
    </div>
  );

};

export default CommunityManagementSimulatorSection;
